import React, { useEffect, useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Container from 'react-bootstrap/Container'

import Layout from "../../components/layout"
import FeaturedImg from "../../components/featuredImg"
import Button from "../../components/button"
import SideNav from "../../components/sidenav"
import Breadcrumbs from "../../components/breadcrumbs"
import CALinks from './calinks'
// import firebase from "gatsby-plugin-firebase"

import pages from "../../data/pages"
import { db } from "../../../utils/firebase"

import './services.scss'

const Services = () => {

  const data = useStaticQuery(graphql`
    query {
      featuredImgDesktop: file(
        relativePath: { eq: "20-MIM-0008_Secondary_CA-Services-4.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      featuredImgMobile: file(
        relativePath: { eq: "20-MIM-0008_Mobile-8.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const [content, setContent] = useState("")
  useEffect(() => {
    // firebase.database().ref("/content")
    console.log("test")
    typeof window !== 'undefined' && db.collection("content").doc("custom-manure-pumping").get().then(docref => {
      // console.log(docref.data())
      if (docref.exists) {

        setContent(docref.data().content)
      }
    })
      .catch(alert)
  }, [])

  return (
    <Layout id="about-us-page" className="secondary-page">
      <Breadcrumbs currentPath={[pages[0], pages[0].children[0]]}></Breadcrumbs>
      <div className="content-sidebar-wrapper">
        <SideNav header={pages[0].title} menu={pages[0].children}></SideNav>
        <Container className="main-content-left">
          <FeaturedImg
            data={data}
            html={`Custom Manure <br> Pumping & <br> Application`}
          ></FeaturedImg>
          <section style={{ marginBottom: "16rem" }}>
            <article>
              <div dangerouslySetInnerHTML={{ __html: content }}></div>
              {/* <p>MidMichigan Ag Service offers custom manure pumping using a drag hose system. This approach leads to decreased soil compaction, compared to tank spreaders. Drag hose application also allows us to top spread or inject nutrients at increased speed and pump more slurry (up to 3.5 miles out from the source). We pride ourselves on utilizing the latest application equipment to provide this service to growers with different crop and field needs. MidMichigan Ag Service has also expanded its application capabilities by operating a 48' Puck Pull-type Toolbar, 32' injector toolbar, and a 48’ dribble boom for top dressing hay fields.</p> */}
              <p>
                Ask about our current{" "}
                <strong className="green">5% discount</strong> promotion!
              </p>

              <Button
                style={{ width: "fit-content" }}
                label="Contact Us Today!"
                to={pages[1].path}
                className="smaller-text-mobile"
              ></Button>
            </article>
            <CALinks currentItem={pages[0].children[0].title}></CALinks>
          </section>
        </Container>
      </div>
    </Layout>
  )
}

export default Services